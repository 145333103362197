const Anchor = (($) => {
  class Anchor {
    constructor() {
      this.$window = $(window);
      this.$document = $(document);
      this.$body = $("body");
      this.$header = $("header.global-header");
      this.$anchors = $('a[href*="#"]:not([href="#"]):not([href="#0"])');
    }

    updateWpAdminBarHeight() {
      const headerHeight = this.$header.outerHeight(true) || 0; // Includes margins if true is needed
      const headerTopCssValue = parseFloat(this.$header.css("top")) || 0; 
      this.headerHeight = headerHeight + headerTopCssValue; // Store the total offset for use in click handler
      document.documentElement.style.setProperty('--header-bar-height', `${headerHeight}px`);
    }

    scrollToTarget(target) {
      const targetOffset = $(target).offset().top;
      const offsetPosition = targetOffset - this.headerHeight; // Adjust scroll position by header height

      $('html, body').animate({
        scrollTop: offsetPosition
      }, 500); // Duration of the animation in milliseconds
    }

    onAnchorClick(event) {
      const target = $(event.currentTarget).attr('href');
      const currentUrl = window.location.href.split('#')[0]; // Get the current URL without the hash

      // Check if the target is a full URL or just a hash
      let targetHash;
      if (target.includes('#')) {
        targetHash = target.split('#')[1]; // Extract the hash part
      }

      if (targetHash) {
        const fullTarget = `#${targetHash}`; // Ensure the target is only the hash
        const targetElement = $(fullTarget);

        // Check if the target element exists on the current page
        if (targetElement.length) {
          event.preventDefault(); // Prevent default jump to anchor if target element exists
          this.updateWpAdminBarHeight(); // Ensure the header height is updated
          this.scrollToTarget(fullTarget); // Smoothly scroll to the target
        }
      }
    }

    setEventBindings() {
      this.$window.on("load resize scroll", this.updateWpAdminBarHeight.bind(this));
      this.$anchors.on("click", this.onAnchorClick.bind(this));
    }

    init() {
      this.setEventBindings();
      this.updateWpAdminBarHeight(); // Initial update for the header height
    }
  }

  return {
    init() {
      const module = new Anchor();
      module.init();
    },
  };
})(window.jQuery);

// Initialize the module
(($) => {
  Anchor.init();
})(window.jQuery);
