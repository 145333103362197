import { toggleOther } from "./form-utils";

const EventFilters = (($) => {
    class EventFilters {
        constructor(node) {
            this.node = node;
            this.$node = $(this.node);

        }

        init() {
            toggleOther(this.$node.find('[name="range"]'), this.$node.find('[data-field="month[]"]'), 'other_month');
        }
    }

    return {
        init({ selector } = {}) {
            return jQuery(selector).map((index, node) => {
                const module = new EventFilters(node);
                module.init();
            });
        },
    };
})(window.jQuery);

(($) => {
    let context = $(document);

    EventFilters.init({
        selector: "[data-event-filters]",
    });
})(window.jQuery);
