const Menu = (($) => {
  class Menu {
    constructor(node) {
      this.node = node;
      this.$node = $(node);
      this.$node.attr("data-menu", true);
      this.$triggers = $("[data-menu-action", this.$node);
      this.$drawer = $("[data-menu-drawer]", this.$node);
      this.$overlay = $("[data-menu-overlay]", this.$node);
    }

    onTriggerClick(event) {
      let target = $(event.currentTarget);
      let action = target.data("menu-action");

      switch (action) {
        case "menu":
          this.open();
          break;
        case "search":
          this.open();
          this.search();
          break;
        case "close":
          this.close();
          break;
      }
    }

    close() {
      this.$drawer.removeClass("active");
      this.$overlay.removeClass("active");
      $("body").removeClass("overflow-hidden");
    }

    open() {
      this.$drawer.addClass("active");
      this.$overlay.addClass("active");
      $("body").addClass("overflow-hidden");
      this.$drawer.scrollTop(0);
    }

    search() {
      this.$node.find('input[type="search"]').focus();
    }

    onBlur(event) {
      let $target = $(event.target);
      if (!$target.closest(this.node).length) {
        this.close();
      }
    }

    init() {
      this.$triggers.on("click", this.onTriggerClick.bind(this));
      $(document).on("click", this.onBlur.bind(this));
    }
  }

  return {
    init({ selector } = {}) {
      return jQuery(selector).map((index, node) => {
        const module = new Menu(node);
        module.init();
      });
    },
  };
})(window.jQuery);

(($) => {
  let context = $(document);

  Menu.init({
    selector: "[data-menu]",
  });

  $(document).on("ajaxComplete", () => {
    Menu.init({
      selector: '[data-menu="false"]',
    });
  });
})(window.jQuery);

// replaces close icon with hamburger icon
jQuery(".wp-block-navigation__responsive-container-close svg").replaceWith(
  '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 5v1.5h14V5H5zm0 7.8h14v-1.5H5v1.5zM5 19h14v-1.5H5V19z"></path></svg>'
);
// Enables scrolling again when a nav item is clicked for mobile
jQuery(document).ready(function () {
  jQuery("#modal-2-content > ul > li > a").click(function () {
    jQuery("body").removeAttr("style");
  });
});
